import megaex from "~/assets/icons/megaex.png";

export function MegaEx() {
    return (
        <img
            src={megaex}
            alt="fail"
            class="mx-auto w-1/2 max-w-[30vh] flex-shrink"
        />
    );
}
